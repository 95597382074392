.experience-card {
    width: 300px;
    height: 530px;
    background-color: rgba(201, 201, 201, 0.552);
    padding: 20px 10px 10px 10px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.473);
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.experience-card ul {
    font-family: 'Della Respira', serif;
    list-style: square;
    padding-left: 20px;
    text-align: left;
}

@media (max-width: 1320px) {
    .experience-card {
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .experience-card {
        width: 100%;
        margin-bottom: 10px;
    }
}