.story-container {
    position: relative;
    max-width: 1320px;
    width: 100vw;
    padding-bottom: 50px;
    background-color: var(--clr1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
}

.story-container h1 {
    color: white;
    text-align: center;
}

.story-container.loading {
    display: none;
}

.story-container .story-list {
    width: calc(100% - 50px);
    font-family: 'Della Respira', serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

@media (max-width: 480px) {
    .story-container {
        width: 100%;
    }
}
