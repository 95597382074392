.resume-container {
    position: relative;
    max-width: 1320px;
    width: 100vw;
    background-color: var(--clr2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.resume-container.loading {
    display: none;
}

@media (max-width: 480px) {
    .resume-container {
        width: 100%;
    }
}
