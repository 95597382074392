@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Della+Respira&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--clr1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --clr1: #151D3C;
  --clr2: #575e95;
  --clr3: #ffdd00;
}
.App {
  width: 100vw;
  max-width: 1320px;
  position: relative;
  background-color: #151D3C;
  background-color: var(--clr1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.App .loader {
  height: 100vh;
  width: 100vw;
  background-color: #151D3C;
  background-color: var(--clr1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.App .navmenu-wrapper {
  display: none;
}

@media (max-width: 480px){
  .App .navbar-wrapper {
    display: none;
  }
  
  .App .navmenu-wrapper {
    display: block;
  }
}

.title-container {
    position: relative;
    height: 800px;
    max-width: 1320px;
    width: 100vw;
    background-color: #151D3C;
    overflow: hidden;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 2s ease-in-out;
}

.title-container.loading {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
}

.title-container h1 {
    position: absolute;
    width: 276px;
    height: 168px;
    left: 160px;
    top: 150px;
  
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 84px;
    letter-spacing: 0.2em;
    text-align: center;
    text-shadow: 1px 1px 1px black;
  
    color: white;
    z-index: 1;
    cursor: default;
}

.title-container #main-portrait {
    position: absolute;
    height: 700px;
    bottom: 0px;
    left: 400px;
    z-index: 1;
    pointer-events: none;
}

.title-container #logo {
    position: absolute;
    top: 10px;
    left: 0;
    height: 100px;
}

.title-container #rect1 {
    position: absolute;
    bottom: 230px;
    left: 230px;
    width: 107px;
    height: 12px;
    background-color: #021DA8;
}

.title-container #diamond {
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 20px;
    transform: rotate(-30deg);
    top: 100px;
    right: 100px;
    background: var(--clr3);
    z-index: 0;
    animation: spin 20s infinite linear;
}
  
@keyframes spin {
    from { 
        transform: rotateZ(0deg) rotate(-45deg); 
    }
    to {
        transform: rotateZ(360deg) rotate(-45deg); 
    }
}

.title-container #diamond2 {
    position: absolute;
    height: 350px;
    width: 350px;
    border-radius: 50px;
    top: 110px;
    left: 110px;
    background: var(--clr3);
    z-index: 0;
    animation: spin2 30s infinite linear;
}

@keyframes spin2 {
    from { 
        transform: rotateZ(360deg) rotate(-45deg); 
    }
    to {
        transform: rotateZ(0deg) rotate(-45deg); 
    }
}

@media (max-width: 1320px) {
    .title-container #diamond {
        display: none;
    }
    
    .title-container h1 {
        position: relative;
        height: 168px;
        left: 150px;
        right: 0px;
        top: 0px;
        font-size: 64px;
        margin-top: 110px;
    }

    .title-container #diamond2 {
        height: 200px;
        width: 200px;
        border-radius: 20px;
        top: 90px;
        left: 180px;
    }

    .title-container #main-portrait {
        position: absolute;
        height: 700px;
        bottom: 0px;
        right: 10px;
        left: auto;
        z-index: 0;
        pointer-events: none;
    }

    .title-container #rect1 {
        position: absolute;
        top: 400px;
        left: calc(20% + 50px);
    }
}

@media (max-width: 780px) {
    .title-container #main-portrait {
        position: absolute;
        height: 700px;
        bottom: 0px;
        right: -200px;
        left: auto;
        z-index: 0;
        pointer-events: none;
    }
}


@media (max-width: 480px) {
    .title-container {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .title-container #logo {
        position: absolute;
        top: 10px;
        left: 10px;
        height: 50px;
    }

    .title-container h1 {
        position: relative;
        height: 168px;
        left: 0px;
        right: 0px;
        top: 0px;
        font-size: 64px;
        margin-top: 110px;
    }

    .title-container #diamond {
        display: block;
        top: 100px;
        right: auto;
        left: auto;
       
    }

    .title-container #main-portrait {
        position: absolute;
        height: 400px;
        bottom: 0px;
        left: 50%;
        opacity: 0.5;
        z-index: 0;
    }

    .title-container #diamond2 {
        display: none;
    }

    .title-container #rect1 {
        position: relative;
        top: 0;
        bottom: 0px;
        left: 0px;
    }
}




  
.social-container {
    position: absolute;
    left: 175px;
    bottom: 150px;
    width: 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
}

.social-container a {
    height: 64;
    width: 64;
    transition: all 0.3s ease-in-out
}

.social-container a:hover {
    transform: scale(1.2);
}

@media (max-width: 1320px) {
    .social-container {
        position: relative;
        left: 20%;
        bottom: 0;
        right: 0;
        top: 0;
    }
}

@media (max-width: 480px) {
    .social-container {
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
    }
}
.intro-container {
    position: absolute;
    right: 100px;
    top: 300px;
    color: white;
    font-family: 'Della Respira', serif;
    overflow-x: hidden;
}
    
.intro-container #top {
    margin-right: auto;
    margin-left: auto;
    width: 300px;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.5em;
    color: white;
    cursor: default;
}

.intro-container #bottom {
    margin-right: auto;
    margin-left: auto;
    width: 350px;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.5em;
    color: white;
    cursor: default;
}

.intro-container a {
    font-size: 48px;
    color: rgba(192, 201, 249, 1);
}

@media (max-width: 1320px) {
    .intro-container {
        position: relative;
        left: 10px;
        top: 0px;
        width: 50%;
    }

    .intro-container #top {
        width: 100%;
        padding: 10px;
        font-size: 36px;
    }

    .intro-container #bottom {
        width: 100%;
        padding: 10px;
        margin-bottom: 50px;
    }
}

@media (max-width: 480px) {
    .intro-container {
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
    }

    .intro-container #top {
        width: 100%;
        padding: 10px;
        font-size: 36px;
    }

    .intro-container #bottom {
        width: 100%;
        padding: 10px;
        margin-bottom: 50px;
    }
}

.projects-container {
    position: relative;
    max-width: 1320px;
    width: 100vw;
    background-color: var(--clr2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.projects-container h1 {
    color: white;
}

.projects-container.loading {
    display: none;
}

.projects-container .projects-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media (max-width: 480px) {
    .projects-container {
        width: 100%;
    }

    .projects-container .projects-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}


:root {
    --level-one: translateZ(20px);
    --level-two: translateZ(40px);
    --level-three: translateZ(60px);
}

.project-card {
    width: 300px;
    height: 350px;
    margin: 30px;
    opacity: 1;
    transition: all 1s ease-in-out;
}

.project-card.offscreen {
    right: 0;
    opacity: 0;
    transform: translateX(50%);
}

.project-card .card__content {
    height: 100%;
    width: 100%;
    text-align: center;
    position: relative;
    align-items: center;
    transition: transform 1.5s;
    transform-style: preserve-3d;
}

.project-card:hover .card__content {
   transform: rotateY(.5turn);
}

.project-card .card__content .card___front,
.project-card .card__content .card__back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.project-card .card__content .card__front {
    position: relative;
    height: 100%;
    display: flex;
    border-radius: 38px 0 38px 0;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transform-style: preserve-3d;
}

.project-card .card__content .card__back {
    background: #151D3C;
    border-radius: 0 38px 0 38px;
    padding: 10px;
    transform: rotateY(180deg);
}


.project-card .card__content .card__front .bg-img {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 38px 0 38px 0;
    background: rgba(166, 161, 238);
    overflow: hidden;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.project-card .card__content .card__front .bg-img img {
    position: relative;
    height: 100%;
    width: 100%;
    opacity: 0.2;
}

.project-card .card__content .card__front::before {
    position: absolute;
    content: '';
    height: 85%;
    width: 85%;
    border-radius: 10px;
    border: 3px solid black;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: translateZ(20px);
    transform: var(--level-one);
    z-index: 1;
}

.project-card .card__content .card__front .title {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateZ(60px);
    transform: var(--level-three);
}

.project-card .card__content .card__front .techs-list {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    transform: translateZ(40px);
    transform: var(--level-two);
}

.project-card .card__content .card__back .links-list {
    transform: translateZ(60px);
    transform: var(--level-three);
}

.project-card .card__content .card__back p {
    font-weight: bold;
    transform: translateZ(40px);
    transform: var(--level-two);
    color: white;
    cursor: default; 
}

.project-card .card__content .card__back h2 {
    color: white;
    transform: translateZ(60px);
    transform: var(--level-three);
    cursor: default; 
}

.project-card .card__content .card__back .bullets-list {
    font-size: 14px;
    color: white;
    transform: translateZ(20px);
    transform: var(--level-one);
    cursor: default; 
}

.project-card .card__content .card__back .links-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.project-card .card__content .card__back .links-list a {
    text-decoration: none;
    font-weight: bold;
    font-size: 24px;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.50);
    transition: all .3s ease-in-out;
}

.project-card .card__content .card__back .links-list a:hover {
    transform: scale(1.2);
    color: rgb(20, 197, 220);
}

@media (max-width: 480px) {
    .project-card {
        margin: 15px 0;
    }
} 
.story-container {
    position: relative;
    max-width: 1320px;
    width: 100vw;
    padding-bottom: 50px;
    background-color: var(--clr1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
}

.story-container h1 {
    color: white;
    text-align: center;
}

.story-container.loading {
    display: none;
}

.story-container .story-list {
    width: calc(100% - 50px);
    font-family: 'Della Respira', serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

@media (max-width: 480px) {
    .story-container {
        width: 100%;
    }
}

.story-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    transition: all 0.7s ease-in-out
}

.story-card .icon {
    color: white;
    height: 200px;
    margin: 20px;
}

.story-card .content {
    color: white;
    font-size: 30px;
    flex: 1 1;
}

.story-card.left {
    flex-direction: row-reverse;
}

.story-card.offscreen.left {
    opacity: 0;
    transform: translateX(-50%)
}

.story-card.offscreen.right {
    opacity: 0;
    transform: translateX(50%)
}

/* @media (max-width: 1320px) {
    .story-card {
        width: 100%;
        margin: 0 50px;
    }
} */

@media (max-width: 480px) {
    .story-card {
        width: 95%;
        flex-direction: column;
        padding: 10px;
        text-align: center;
    }

    .story-card.left {
        flex-direction: column;
    }
}
.resume-container {
    position: relative;
    max-width: 1320px;
    width: 100vw;
    background-color: var(--clr2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.resume-container.loading {
    display: none;
}

@media (max-width: 480px) {
    .resume-container {
        width: 100%;
    }
}

.skills-container {
    width: 90%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.skills-container .skills-list {
    padding: 20px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.49);
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    background: rgba(128, 128, 128, 0.442);
}

@media (max-width: 480px) {
    .skills-container {
        width: 100%;
    }
}
.skill-card {
    width: 180px;
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.504);
    color: black;
    background-color: var(--clr3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.skill-card img {
    margin-right: 5px;
}

.skill-card h2 {
    font-size: 20px;
}

@media (max-width: 480px) {
    .skill-card {
        width: 120px;
        margin: 10px;
    }
    .skill-card h2 {
        font-size: 12px;
    }
}
.education-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.education-container .education-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
} 

.education-container h1 {
    color: white;
}

@media (max-width: 480px) {
    .education-container .education-list {
        flex-direction: column;
    }
}

.education-card {
    position: relative;
    width: 450px;
    color: white;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 10px;
    background: rgba(128, 128, 128, 0.442);
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.497);
}

.education-card h2 {
    color: rgb(66, 4, 132);
    margin-top: 0;
    padding-left: 5px;
    border-radius: 5px;
    background-color: honeydew;
}

.education-card .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    width: 50%;
    max-width: 150px;
    background-color: honeydew;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 480px) {
    .education-card {
        width: 100%;
    }

    .education-card h2 {
        width: 45%;
    }

    .education-card .icon {
        top: 10px;
        right: 25px;
    }
}
.experience-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.experience-container h1 {
    color: white;
}
.experience-container .experience-list {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}

@media (max-width: 480px) {
    .experience-container .experience-list {
        width: 100%;
    }
}
.experience-card {
    width: 300px;
    height: 530px;
    background-color: rgba(201, 201, 201, 0.552);
    padding: 20px 10px 10px 10px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.473);
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.experience-card ul {
    font-family: 'Della Respira', serif;
    list-style: square;
    padding-left: 20px;
    text-align: left;
}

@media (max-width: 1320px) {
    .experience-card {
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .experience-card {
        width: 100%;
        margin-bottom: 10px;
    }
}
.contact-container {
    max-width: 1320px;
    width: 100vw;
    margin-bottom: 50px;
    background-color: #151D3C;
    border-radius: 0 0 67px 67px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    overflow-x: hidden;
}

.contact-container h1 {
    color: white;
    text-align: center;
}

.contact-container.loading {
    display: none;
}

@media (max-width: 480px) {
    .contact-container {
        width: 100%;
    }
}
.contact-form {
    position: relative;
    width: 400px;
}

.contact-form .input-div {
    position: relative;
}

.contact-form .input-div input{
    width: 100%;
    padding: 10px;
    font-size: 18px;
    background: transparent;
    color: white;
    border-radius: 0;
    border: none;
    border-bottom: 3px solid white;
}

.contact-form .input-div input:focus {
    outline: none;
}

.contact-form .input-div label {
    position: absolute;
    top: 20px;
    left: 10px;
    color: #fff;
    font-size: 1.2em;
    pointer-events: none;
    transition: .5s;
}

.contact-form .input-div .active {
    color: rgb(20, 197, 220);
    top: 0px;
    left: 5px;
    font-size: 14px;
}

.contact-form label {
    position: relative;
    left: 10px;
    color: white;
    font-size: 18px;
}

.contact-form textarea {
    font-family: 'Roboto';
    font-size: 18px;
    resize: none;
    width: 100%;
    height: 200px;
    padding: 10px;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    background: transparent;
}

.contact-form input[type='submit'] {
    background: transparent;
    font-size: 20px;
    border-radius: 5px;
    margin: 10px 0 10px 0;
    padding: 5px;
    color: white;
    border: 2px solid white;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.contact-form input[type='submit']:hover {
    background-color: rgba(128, 128, 128, 0.23);
    transform: scale(1.1);
}

.contact-form input[type='submit']:focus {
    outline: none;
} 

.contact-form .success {
    color: rgb(6, 251, 6);
    font-size: 20px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.463);
    align-self: center;
}

.contact-form .failure {
    color: red;
    font-size: 20px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.463);
    align-self: center;
}

@media (max-width: 480px) {
    .contact-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-form textarea {
        width: 90%;
    }

    .contact-form .input-div {
        width: 100%;
    }

    .contact-form .input-div input{
        width: 100%;
    }

}


.video-container {
    position: relative;
    max-width: 1320px;
    width: 100vw;
    height: 0;
    padding-bottom: 56.25%;
    background-color: var(--clr2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.video-container iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%
}

.video-container.loading {
    display: none;
}
.nav-bar {
    position: absolute;
    top: 35px;
    right: 50px;
    width: 400px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-bar.loading {
    display: none;
}

.nav-bar a {
    color: white;
    font-family: 'Roboto';
    font-size: 18px;
    text-decoration: none;
    transition: all 0.3s;
}

.nav-bar a:hover {
    transform: scale(1.1);
    color: rgb(20, 197, 220)
} 

.nav-menu-button {
    position: fixed;
    right: 5px;
    top: 5px;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    background-color: var(--clr3);
    z-index: 10;
}

.nav-menu-button.loading {
    display: none;
}

.nav-menu-button:after {
    content: '';
    position: absolute;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: var(--clr1)
}

.nav-menu-button .bar1 {
    content: '';
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-55%);
    height: 5px;
    width: 40px;
    border-radius: 50px;
    background-color: var(--clr3);
    z-index: 1;
}

.nav-menu-button .bar2 {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-55%);
    height: 5px;
    width: 40px;
    border-radius: 50px;
    background-color: var(--clr3);
    z-index: 1;
}

.nav-menu-button .bar3 {
    content: '';
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-55%);
    height: 5px;
    width: 40px;
    border-radius: 50px;
    background-color: var(--clr3);
    z-index: 1;
}

.nav-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: var(--clr3);
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: all 1s ease-out;
}

.nav-menu.show {
    -webkit-clip-path: circle(1100px at 90% -20%);
            clip-path: circle(1100px at 90% -20%);
}

.nav-menu.hide {
    -webkit-clip-path: circle(100px at 90% -20%);
            clip-path: circle(100px at 90% -20%);
}

.nav-menu a {
    text-decoration: none;
    color: var(--clr1);
}
.blog-container {
    max-width: 1320px;
    width: 100%;
    margin-bottom: 50px;
}

.blog-container > h1 {
    color: white;
    text-align: center;
}

.blog-container .blog-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.blog-container.loading {
    display: none;
}
.blog-card {
    background: linear-gradient(45deg, gold, rgb(255, 179, 0), yellow);
    width: 250px;
    height: 350px;
    padding: 0 10px 10px 10px;
    margin: 5px;
    border-radius: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.blog-card h2 {
    text-align: center;
    font-size: 18px;
}

.blog-card h3 {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px;
    background-color: black;
    text-align: right;

    font-size: 18px;
    color: rgb(162, 162, 162);

}

.blog-card img {
    height: 150px;
    -webkit-clip-path: circle();
            clip-path: circle();
}

.blog-card a {
    text-decoration: none;
}
