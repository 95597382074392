.projects-container {
    position: relative;
    max-width: 1320px;
    width: 100vw;
    background-color: var(--clr2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.projects-container h1 {
    color: white;
}

.projects-container.loading {
    display: none;
}

.projects-container .projects-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media (max-width: 480px) {
    .projects-container {
        width: 100%;
    }

    .projects-container .projects-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

