.title-container {
    position: relative;
    height: 800px;
    max-width: 1320px;
    width: 100vw;
    background-color: #151D3C;
    overflow: hidden;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 2s ease-in-out;
}

.title-container.loading {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
}

.title-container h1 {
    position: absolute;
    width: 276px;
    height: 168px;
    left: 160px;
    top: 150px;
  
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 84px;
    letter-spacing: 0.2em;
    text-align: center;
    text-shadow: 1px 1px 1px black;
  
    color: white;
    z-index: 1;
    cursor: default;
}

.title-container #main-portrait {
    position: absolute;
    height: 700px;
    bottom: 0px;
    left: 400px;
    z-index: 1;
    pointer-events: none;
}

.title-container #logo {
    position: absolute;
    top: 10px;
    left: 0;
    height: 100px;
}

.title-container #rect1 {
    position: absolute;
    bottom: 230px;
    left: 230px;
    width: 107px;
    height: 12px;
    background-color: #021DA8;
}

.title-container #diamond {
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 20px;
    transform: rotate(-30deg);
    top: 100px;
    right: 100px;
    background: var(--clr3);
    z-index: 0;
    animation: spin 20s infinite linear;
}
  
@keyframes spin {
    from { 
        transform: rotateZ(0deg) rotate(-45deg); 
    }
    to {
        transform: rotateZ(360deg) rotate(-45deg); 
    }
}

.title-container #diamond2 {
    position: absolute;
    height: 350px;
    width: 350px;
    border-radius: 50px;
    top: 110px;
    left: 110px;
    background: var(--clr3);
    z-index: 0;
    animation: spin2 30s infinite linear;
}

@keyframes spin2 {
    from { 
        transform: rotateZ(360deg) rotate(-45deg); 
    }
    to {
        transform: rotateZ(0deg) rotate(-45deg); 
    }
}

@media (max-width: 1320px) {
    .title-container #diamond {
        display: none;
    }
    
    .title-container h1 {
        position: relative;
        height: 168px;
        left: 150px;
        right: 0px;
        top: 0px;
        font-size: 64px;
        margin-top: 110px;
    }

    .title-container #diamond2 {
        height: 200px;
        width: 200px;
        border-radius: 20px;
        top: 90px;
        left: 180px;
    }

    .title-container #main-portrait {
        position: absolute;
        height: 700px;
        bottom: 0px;
        right: 10px;
        left: auto;
        z-index: 0;
        pointer-events: none;
    }

    .title-container #rect1 {
        position: absolute;
        top: 400px;
        left: calc(20% + 50px);
    }
}

@media (max-width: 780px) {
    .title-container #main-portrait {
        position: absolute;
        height: 700px;
        bottom: 0px;
        right: -200px;
        left: auto;
        z-index: 0;
        pointer-events: none;
    }
}


@media (max-width: 480px) {
    .title-container {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .title-container #logo {
        position: absolute;
        top: 10px;
        left: 10px;
        height: 50px;
    }

    .title-container h1 {
        position: relative;
        height: 168px;
        left: 0px;
        right: 0px;
        top: 0px;
        font-size: 64px;
        margin-top: 110px;
    }

    .title-container #diamond {
        display: block;
        top: 100px;
        right: auto;
        left: auto;
       
    }

    .title-container #main-portrait {
        position: absolute;
        height: 400px;
        bottom: 0px;
        left: 50%;
        opacity: 0.5;
        z-index: 0;
    }

    .title-container #diamond2 {
        display: none;
    }

    .title-container #rect1 {
        position: relative;
        top: 0;
        bottom: 0px;
        left: 0px;
    }
}




  