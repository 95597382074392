.contact-container {
    max-width: 1320px;
    width: 100vw;
    margin-bottom: 50px;
    background-color: #151D3C;
    border-radius: 0 0 67px 67px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    overflow-x: hidden;
}

.contact-container h1 {
    color: white;
    text-align: center;
}

.contact-container.loading {
    display: none;
}

@media (max-width: 480px) {
    .contact-container {
        width: 100%;
    }
}