.social-container {
    position: absolute;
    left: 175px;
    bottom: 150px;
    width: 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
}

.social-container a {
    height: 64;
    width: 64;
    transition: all 0.3s ease-in-out
}

.social-container a:hover {
    transform: scale(1.2);
}

@media (max-width: 1320px) {
    .social-container {
        position: relative;
        left: 20%;
        bottom: 0;
        right: 0;
        top: 0;
    }
}

@media (max-width: 480px) {
    .social-container {
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
    }
}