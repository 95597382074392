.contact-form {
    position: relative;
    width: 400px;
}

.contact-form .input-div {
    position: relative;
}

.contact-form .input-div input{
    width: 100%;
    padding: 10px;
    font-size: 18px;
    background: transparent;
    color: white;
    border-radius: 0;
    border: none;
    border-bottom: 3px solid white;
}

.contact-form .input-div input:focus {
    outline: none;
}

.contact-form .input-div label {
    position: absolute;
    top: 20px;
    left: 10px;
    color: #fff;
    font-size: 1.2em;
    pointer-events: none;
    transition: .5s;
}

.contact-form .input-div .active {
    color: rgb(20, 197, 220);
    top: 0px;
    left: 5px;
    font-size: 14px;
}

.contact-form label {
    position: relative;
    left: 10px;
    color: white;
    font-size: 18px;
}

.contact-form textarea {
    font-family: 'Roboto';
    font-size: 18px;
    resize: none;
    width: 100%;
    height: 200px;
    padding: 10px;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    background: transparent;
}

.contact-form input[type='submit'] {
    background: transparent;
    font-size: 20px;
    border-radius: 5px;
    margin: 10px 0 10px 0;
    padding: 5px;
    color: white;
    border: 2px solid white;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.contact-form input[type='submit']:hover {
    background-color: rgba(128, 128, 128, 0.23);
    transform: scale(1.1);
}

.contact-form input[type='submit']:focus {
    outline: none;
} 

.contact-form .success {
    color: rgb(6, 251, 6);
    font-size: 20px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.463);
    align-self: center;
}

.contact-form .failure {
    color: red;
    font-size: 20px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.463);
    align-self: center;
}

@media (max-width: 480px) {
    .contact-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-form textarea {
        width: 90%;
    }

    .contact-form .input-div {
        width: 100%;
    }

    .contact-form .input-div input{
        width: 100%;
    }

}

