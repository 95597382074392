.experience-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.experience-container h1 {
    color: white;
}
.experience-container .experience-list {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}

@media (max-width: 480px) {
    .experience-container .experience-list {
        width: 100%;
    }
}