.nav-menu-button {
    position: fixed;
    right: 5px;
    top: 5px;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    background-color: var(--clr3);
    z-index: 10;
}

.nav-menu-button.loading {
    display: none;
}

.nav-menu-button:after {
    content: '';
    position: absolute;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: var(--clr1)
}

.nav-menu-button .bar1 {
    content: '';
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-55%);
    height: 5px;
    width: 40px;
    border-radius: 50px;
    background-color: var(--clr3);
    z-index: 1;
}

.nav-menu-button .bar2 {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-55%);
    height: 5px;
    width: 40px;
    border-radius: 50px;
    background-color: var(--clr3);
    z-index: 1;
}

.nav-menu-button .bar3 {
    content: '';
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-55%);
    height: 5px;
    width: 40px;
    border-radius: 50px;
    background-color: var(--clr3);
    z-index: 1;
}

.nav-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: var(--clr3);
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: all 1s ease-out;
}

.nav-menu.show {
    clip-path: circle(1100px at 90% -20%);
}

.nav-menu.hide {
    clip-path: circle(100px at 90% -20%);
}

.nav-menu a {
    text-decoration: none;
    color: var(--clr1);
}