:root {
    --level-one: translateZ(20px);
    --level-two: translateZ(40px);
    --level-three: translateZ(60px);
}

.project-card {
    width: 300px;
    height: 350px;
    margin: 30px;
    opacity: 1;
    transition: all 1s ease-in-out;
}

.project-card.offscreen {
    right: 0;
    opacity: 0;
    transform: translateX(50%);
}

.project-card .card__content {
    height: 100%;
    width: 100%;
    text-align: center;
    position: relative;
    align-items: center;
    transition: transform 1.5s;
    transform-style: preserve-3d;
}

.project-card:hover .card__content {
   transform: rotateY(.5turn);
}

.project-card .card__content .card___front,
.project-card .card__content .card__back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.project-card .card__content .card__front {
    position: relative;
    height: 100%;
    display: flex;
    border-radius: 38px 0 38px 0;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transform-style: preserve-3d;
}

.project-card .card__content .card__back {
    background: #151D3C;
    border-radius: 0 38px 0 38px;
    padding: 10px;
    transform: rotateY(180deg);
}


.project-card .card__content .card__front .bg-img {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 38px 0 38px 0;
    background: rgba(166, 161, 238);
    overflow: hidden;
    backface-visibility: hidden;
}

.project-card .card__content .card__front .bg-img img {
    position: relative;
    height: 100%;
    width: 100%;
    opacity: 0.2;
}

.project-card .card__content .card__front::before {
    position: absolute;
    content: '';
    height: 85%;
    width: 85%;
    border-radius: 10px;
    border: 3px solid black;
    backface-visibility: hidden;
    transform: var(--level-one);
    z-index: 1;
}

.project-card .card__content .card__front .title {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: var(--level-three);
}

.project-card .card__content .card__front .techs-list {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    transform: var(--level-two);
}

.project-card .card__content .card__back .links-list {
    transform: var(--level-three);
}

.project-card .card__content .card__back p {
    font-weight: bold;
    transform: var(--level-two);
    color: white;
    cursor: default; 
}

.project-card .card__content .card__back h2 {
    color: white;
    transform: var(--level-three);
    cursor: default; 
}

.project-card .card__content .card__back .bullets-list {
    font-size: 14px;
    color: white;
    transform: var(--level-one);
    cursor: default; 
}

.project-card .card__content .card__back .links-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.project-card .card__content .card__back .links-list a {
    text-decoration: none;
    font-weight: bold;
    font-size: 24px;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.50);
    transition: all .3s ease-in-out;
}

.project-card .card__content .card__back .links-list a:hover {
    transform: scale(1.2);
    color: rgb(20, 197, 220);
}

@media (max-width: 480px) {
    .project-card {
        margin: 15px 0;
    }
} 