.blog-container {
    max-width: 1320px;
    width: 100%;
    margin-bottom: 50px;
}

.blog-container > h1 {
    color: white;
    text-align: center;
}

.blog-container .blog-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.blog-container.loading {
    display: none;
}