@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Della+Respira&display=swap');

:root {
  --clr1: #151D3C;
  --clr2: #575e95;
  --clr3: #ffdd00;
}
.App {
  width: 100vw;
  max-width: 1320px;
  position: relative;
  background-color: var(--clr1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.App .loader {
  height: 100vh;
  width: 100vw;
  background-color: var(--clr1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.App .navmenu-wrapper {
  display: none;
}

@media (max-width: 480px){
  .App .navbar-wrapper {
    display: none;
  }
  
  .App .navmenu-wrapper {
    display: block;
  }
}
