.skills-container {
    width: 90%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.skills-container .skills-list {
    padding: 20px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.49);
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    background: rgba(128, 128, 128, 0.442);
}

@media (max-width: 480px) {
    .skills-container {
        width: 100%;
    }
}