.video-container {
    position: relative;
    max-width: 1320px;
    width: 100vw;
    height: 0;
    padding-bottom: 56.25%;
    background-color: var(--clr2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.video-container iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%
}

.video-container.loading {
    display: none;
}