.nav-bar {
    position: absolute;
    top: 35px;
    right: 50px;
    width: 400px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-bar.loading {
    display: none;
}

.nav-bar a {
    color: white;
    font-family: 'Roboto';
    font-size: 18px;
    text-decoration: none;
    transition: all 0.3s;
}

.nav-bar a:hover {
    transform: scale(1.1);
    color: rgb(20, 197, 220)
} 
