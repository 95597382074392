.intro-container {
    position: absolute;
    right: 100px;
    top: 300px;
    color: white;
    font-family: 'Della Respira', serif;
    overflow-x: hidden;
}
    
.intro-container #top {
    margin-right: auto;
    margin-left: auto;
    width: 300px;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.5em;
    color: white;
    cursor: default;
}

.intro-container #bottom {
    margin-right: auto;
    margin-left: auto;
    width: 350px;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.5em;
    color: white;
    cursor: default;
}

.intro-container a {
    font-size: 48px;
    color: rgba(192, 201, 249, 1);
}

@media (max-width: 1320px) {
    .intro-container {
        position: relative;
        left: 10px;
        top: 0px;
        width: 50%;
    }

    .intro-container #top {
        width: 100%;
        padding: 10px;
        font-size: 36px;
    }

    .intro-container #bottom {
        width: 100%;
        padding: 10px;
        margin-bottom: 50px;
    }
}

@media (max-width: 480px) {
    .intro-container {
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
    }

    .intro-container #top {
        width: 100%;
        padding: 10px;
        font-size: 36px;
    }

    .intro-container #bottom {
        width: 100%;
        padding: 10px;
        margin-bottom: 50px;
    }
}
